var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper msg-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('pending-items-internal', {
    attrs: {
      "title": false
    }
  }), _vm._m(0), _c('pending-search', {
    on: {
      "onSearch": _vm.searchData,
      "onClear": _vm.clearData
    }
  }), _c('div', {
    staticClass: "table-card"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center top-pagination"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filters
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1), _c('simple-table', {
    attrs: {
      "columns": _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "tableBody",
      fn: function fn() {
        return [_c('pending-table', {
          attrs: {
            "rows": _vm.rows,
            "colSpan": _vm.columns.length,
            "type": "pendingMember65"
          },
          on: {
            "update:rows": function updateRows($event) {
              _vm.rows = $event;
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filters
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1)], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Members turning 65 or are 65 years old")])]);
}]

export { render, staticRenderFns }